export const UserType = {
  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAIL: "GET_USERS_FAIL",
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAIL: "GET_USER_FAIL",
  UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAIL: "UPDATE_STATUS_FAIL",
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAIL: "CREATE_USER_FAIL",
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL: "UPDATE_USER_FAIL",
  USERS_ROLE_REQUEST: "USERS_ROLE_REQUEST",
  USERS_ROLE_SUCCESS: "USERS_ROLE_SUCCESS",
  USERS_ROLE_FAIL: "USERS_ROLE_FAIL",
  USERS_ROLE_ASSIGN_REQUEST: "USERS_ROLE_ASSIGN_REQUEST",
  USERS_ROLE_ASSIGN_SUCCESS: "USERS_ROLE_ASSIGN_SUCCESS",
  USERS_ROLE_ASSIGN_FAIL: "USERS_ROLE_ASSIGN_FAIL",
  RESEND_EMAIL_SET_PASSWORD_REQUEST: "RESEND_EMAIL_SET_PASSWORD_REQUEST",
  RESEND_EMAIL_SET_PASSWORD_SUCCESS: "RESEND_EMAIL_SET_PASSWORD_SUCCESS",
  RESEND_EMAIL_SET_PASSWORD_FAIL: "RESEND_EMAIL_SET_PASSWORD_FAIL",
  KIOSK_STATUS_FAIL: "KIOSK_STATUS_FAIL",
  KIOSK_STATUS_REQUEST: "KIOSK_STATUS_REQUEST",
  KIOSK_STATUS_SUCCESS: "KIOSK_STATUS_SUCCESS",
  USER_STATUS_FAIL: "USER_STATUS_FAIL",
  USER_STATUS_REQUEST: "USER_STATUS_REQUEST",
  USER_STATUS_SUCCESS: "USER_STATUS_SUCCESS"
};
